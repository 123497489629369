import { initializeApp } from "firebase/app";
import { getFirestore } from "firebase/firestore";

/*const firebaseConfig = {
  apiKey: "AIzaSyDYAW9F1kP29j38dfZuZxK4lppUkSLD25c",
  authDomain: "react-portfolio-abhishek.firebaseapp.com",
  projectId: "react-portfolio-abhishek",
  storageBucket: "react-portfolio-abhishek.appspot.com",
  messagingSenderId: "278164302078",
  appId: "1:278164302078:web:797fe5960bb12b5fca1daf",
};*/

const firebaseConfig = {
  apiKey: "AIzaSyCfL2hWC18QIZpb9fFxk2YK77UfYzvrrtg",
  authDomain: "portfolio-e990c.firebaseapp.com",
  projectId: "portfolio-e990c",
  storageBucket: "gs://portfolio-e990c.appspot.com",
  messagingSenderId: "784562677448",
  appId: "1:784562677448:web:71bf704d4aaff46ab6a7fd",
};

export const app = initializeApp(firebaseConfig);
export const db = getFirestore();
