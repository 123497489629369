import React from "react";
import {
  AiFillGithub,
  AiFillInstagram,
  AiFillYoutube,
  AiOutlineArrowUp,
} from "react-icons/ai";

const Footer = () => {
  return (
    <footer>
      <div>
        <img
          src={"https://avatars.githubusercontent.com/yamlimbu"}
          alt="Founder"
        />

        <h2>Yam Limbu</h2>
        <p>Linus Torvalds: “Talk is cheap. Show me the code.”</p>
        {/* <p>Motivation is temporary, but discipline last forever.</p> */}
      </div>

      <aside>
        <h2>Social Media</h2>

        <article>
          <a href="https://www.youtube.com/@yamlimbu3147" target={"blank"}>
            <AiFillYoutube />
          </a>
          <a href="https://www.instagram.com/yamlimbu_songyak/" target={"blank"}>
            <AiFillInstagram />
          </a>
          <a href="https://github.com/yamlimbu" target={"blank"}>
            <AiFillGithub />
          </a>
        </article>
      </aside>
      <a href="#home">
        <AiOutlineArrowUp />
      </a>
    </footer>
  );
};

export default Footer;
